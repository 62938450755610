/**
 * External dependencies.
 */
import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(theme => createStyles({
	box: {
		position: 'relative',
		marginBottom: '28px',
		'& img': {
			width: '100%'
		}
	},
	boxButton: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)'
	},
	boxButtonDownload: {
		position: 'absolute',
		bottom: '-4px',
		right: '-4px'
	}
}));

const WidgetVideo = ({ modifier, image, type, thumbnail, noDownload }) => {
	const classes = useStyles();

	return (
		<Box className={classes.box + ' ' + modifier}>
			{type.startsWith('video') && (<video controls> <source src={image} type={type}/> </video>)}

			{type.includes('pdf') && (<img src={thumbnail} />)}

			{type.startsWith('image') && (<img src={image} />)}


			{!noDownload && image && <IconButton className={classes.boxButtonDownload} href={image} target="_blank" >
				<i className="ico-download"></i>
			</IconButton>}
		</Box>
	);
};

export default WidgetVideo;